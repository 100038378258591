import React from "react"
import { Provider } from 'react-redux'
import Chat from './Chat';

class ChatContainer extends React.Component {

  render() {
    return (
      <Provider store={window.appStore}>
        <Chat />
      </Provider>
    );
  }
}

export default ChatContainer;
