export const MESSAGE = 'Message';
export const PROPOSITION_MESSAGE = 'PropositionMessage';
export const ACCEPTED_MESSAGE = 'AcceptedMessage';
export const SURCHARGE_MESSAGE = 'SurchargeMessage';
export const PAID_SURCHARGE_MESSAGE = 'PaidSurchargeMessage';
export const REFUND_MESSAGE = 'RefundMessage';

export class ChatHelper {

  static isMessage(message) {
    return message.type == MESSAGE && message.message_type == "normal_message";
  }

  static isAttachment(message) {
    return message.type == MESSAGE && message.message_type == "attachment";
  }

  static isPropositionMessage(message) {
    return message.type == PROPOSITION_MESSAGE;
  }

  static isAcceptedMessage(message) {
    return message.type == ACCEPTED_MESSAGE;
  }

  static isSurchargeMessage(message) {
    return message.type == SURCHARGE_MESSAGE;
  }

  static isPaidSurchargeMessage(message) {
    return message.type == PAID_SURCHARGE_MESSAGE
  }

  static isRefundMessage(message) {
    return message.type == REFUND_MESSAGE;
  }

  static isRequestRefundMessage(message) {
    return message.type == REFUND_MESSAGE && message.message_type == "request_refund";
  }

  static isDoneRefundMessage(message) {
    return message.type == REFUND_MESSAGE && message.message_type == "done_refund";
  }


  static mergeNewMessage(newMessage, copiedData, loggedCustomerId) {
    var canAdd = false;
    var canUpdateMessages = false;
    var conversationMessageIndex = null;

    for (var i = 0; i < copiedData.length; i++) {
      var conversation = copiedData[i];
      if (conversation.id == newMessage.conversation_id) {
        canAdd = true;
        conversationMessageIndex = i;
        if (ChatHelper.isPropositionMessage(newMessage)) {
          if (conversation.fist_proposition_message_hash_id == null) {
            conversation.fist_proposition_message_hash_id = newMessage.hash_id;
          }
          conversation.last_proposition_message_hash_id = newMessage.hash_id;
        } else if (ChatHelper.isAcceptedMessage(newMessage)) {
          conversation.accepted_time = newMessage.meeting_hour;
        }

        conversation.messages.forEach((element, index) => {
          if (ChatHelper.isPaidSurchargeMessage(newMessage)) {
            if (newMessage.transaction_hash_id == element.transaction_hash_id) {
              element.transaction_completed = newMessage.transaction_completed;
            }
          }

          if (element.hash_id == newMessage.hash_id) {
            // is my message
            if (loggedCustomerId == newMessage.customer_id) {
              element.readed_at = true;
            }

            if (newMessage.message_type == "attachment") { // is attachment
              element.attachment_hash = newMessage.attachment_hash;
              element.verified = true;
              element.deleted_at = newMessage.deleted_at;
              canUpdateMessages = true;
            }
            element.meeting_hour = newMessage.meeting_hour;
            canAdd = false;
            return;
          }
        });
        break;
      }
    }

    if (canUpdateMessages) {
      console.log("UPDATE MESSAGES", newMessage)
      return copiedData;
    }

    if (canAdd) {
      console.log("ADD MESSAGE", newMessage)
      newMessage.verified = true;
      copiedData[conversationMessageIndex] = {
        ...copiedData[conversationMessageIndex],
        messages: copiedData[conversationMessageIndex].messages.concat(newMessage)
      }
      return copiedData;
    } else {
      console.log("NO ADD MESSAGE")
      return null;
    }
  }

  static getErrorMessageFromResponse(error) {
    if (error.response) {
      // Request made and server responded
      if (error.response.data && error.response.data.error) {
        return error.response.data.error;
      } else {
        return 'Błąd aplikacji';
      }
      // console.log(error.response.status);
    } else if (error.request) {
      // The request was made but no response was received
      return 'Problem z połączeniem';
      // return null;
    } else {
      // Something happened in setting up the request that triggered an Error
      return 'Błąd aplikacji - code JS';
      // return null;
      // return error.message;
    }
  }

}
