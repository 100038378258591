import React from "react"
import PropTypes from "prop-types"
import axios from 'axios';
import imageIcon from '../../assets/images/icons/image.svg';
import imageIconDisable from '../../assets/images/icons/image-disable.svg';
import classNames from 'classnames';
import ChatsTime from './chats/ChatsTime';
import { getAvatarUrl } from '../helpers/common_helper';

class ChatAttachmentMessage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showCannotRemoveAttachment: false
    }
  }

  canRemoveAttachment = () => {
    var isMessageOwner = this.props.message.customer_id == this.props.loggedUserHash;
    var isServiceProvider = this.props.isServiceProvider;
    return isMessageOwner || isServiceProvider;
  }

  handleRemoveClick = (e) => {
    var { attachment_hash } = this.props.message;
    e.preventDefault();
    axios.delete(`/remove-attachment/${attachment_hash}`, {}, {
      timeout: 500,
      headers: {
        'Content-Type': 'application/json',
        'accept': 'application/json',
      }
    })
      .then(function (response) {
        console.log("done", response)
      })
      .catch((error) => {
        console.log("fail", error)
        this.setState({ showCannotRemoveAttachment: true }, () => {
          window.setTimeout(() => this.setState({ showCannotRemoveAttachment: false }), 5000);
        });
      });
  }

  messageText = () => {
    var { message, attachment_hash, deleted_at, created_at, avatar_url } = this.props.message;
    var { isServiceProvider, isMyMessage } = this.props;
    var markAsServiceProvider = false;

    if (isMyMessage) {
      markAsServiceProvider = isServiceProvider;
    } else {
      markAsServiceProvider = !isServiceProvider;
    }

    var css = classNames({
      'chat-msg--sp': markAsServiceProvider,
      'chat-msg--cr': !markAsServiceProvider
    });

    var css2 = classNames({
      'chat-msg': true,
      'chat-msg--sp': markAsServiceProvider,
      'chat-msg--cr': !markAsServiceProvider
    });

    if (attachment_hash) {
      if (deleted_at) {
        return (
          <div className={css}>
            <div className="chat-msg__content">
              <ChatsTime date={created_at} isMyMessage={isMyMessage} />
              <figure>
                <img src={getAvatarUrl(avatar_url)} />
              </figure>
              <div className="chat-msg__answer">
                <p className="text-strikethrough">
                  <img src={imageIconDisable} style={{ margin: '-3px 5px 0 0' }} />{message}
                </p>
              </div>
            </div>
          </div>
        )
      } else {
        return (
          <React.Fragment>
            <div className={css2}>
              <div className="chat-msg__content">
                <ChatsTime date={created_at} isMyMessage={isMyMessage} />
                <figure>
                  <img src={getAvatarUrl(avatar_url)} />
                </figure>
                <div className="chat-msg__answer">
                  <p>
                    <a
                      target="_blank"
                      href={`/attachment-download/${attachment_hash}`}
                      style={{ color: '#23b583' }}
                    >
                      <img src={imageIcon} style={{ margin: '-3px 5px 0 0' }} />
                      {message}
                    </a>
                    {this.canRemoveAttachment() && (
                      <a
                        onClick={this.handleRemoveClick}
                        style={{ marginLeft: '15px' }}
                        href="#"
                        title="Usuń załącznik"
                      >Usuń</a>
                    )}
                  </p>
                </div>
              </div>
            </div>
          </React.Fragment>
        );
      }
    } else {
      return message;
    }
  }

  progress = () => {
    var { verified, progress } = this.props.message;
    if (verified !== true) {
      return (<span>[AT: {progress}]</span>);
    }
    return true;
  }

  render() {
    return (
      <React.Fragment>
        {this.messageText()}
        {this.progress()}
        {this.state.showCannotRemoveAttachment && (
          <div className="chat-msg--error">
            <p>Usuwanie załącznika nie powiodło się</p>
          </div>
        )
        }
      </React.Fragment>
    );
  }
}

ChatAttachmentMessage.propTypes = {
  message: PropTypes.object
};

export default ChatAttachmentMessage;
