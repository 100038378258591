import React from "react"
import PropTypes from "prop-types"
import classNames from 'classnames';
import { getAvatarUrl } from '../helpers/common_helper';
import ChatsTime from './chats/ChatsTime';
import { displayNiceAmount } from '../helpers/common_helper';

class ChatPaidSurchargeMessage extends React.Component {

  constructor(props) {
    super(props);
  }

  // only owner (CR) can send that paid the surcharge
  renderMyMessage = () => {
    var { avatar_url, name, created_at, transaction_amount } = this.props.message;
    return (
      <div className="chat-msg-user chat-msg-user--sp">
        <ChatsTime date={created_at} isMyMessage={false} />
        <figure>
          <img alt={name} src={getAvatarUrl(avatar_url)} />
        </figure>
        <p>Dopłaciłeś <strong>{displayNiceAmount(transaction_amount)} zł</strong> do usługi.</p>
      </div>
    );
  }

  renderInterlocutorMessage = () => {
    var { name, avatar_url, created_at, transaction_amount } = this.props.message;
    var css = classNames({
      'chat-msg-user': true
      // 'chat-msg-user--button': true // commented, because of poisonous padding
    });
    return (
      <div className={css}>
        <ChatsTime date={created_at} isMyMessage={false} />
        <figure>
          <img alt={name} src={getAvatarUrl(avatar_url)} />
        </figure>
        <p>
          <strong>{name}</strong> dopłacił{' '}
          <strong>{displayNiceAmount(transaction_amount)} zł</strong>do usługi.
        </p>
      </div>
    );
  }

  render() {
    var { isMyMessage } = this.props;
    return (
      <React.Fragment>
        { isMyMessage && (
          this.renderMyMessage()
        )}
        { !isMyMessage && (
          this.renderInterlocutorMessage()
        )}
      </React.Fragment>
    );
  }
}

ChatPaidSurchargeMessage.propTypes = {
  message: PropTypes.object
};

export default ChatPaidSurchargeMessage;
