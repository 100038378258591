import React from "react"
import { Provider } from 'react-redux'
import Notifications from './Notifications';

class NotificationsContainer extends React.Component {

  render() {
    return (
      <Provider store={window.appStore}>
        <Notifications {...this.props} />
      </Provider>
    );
  }
}

export default NotificationsContainer;

