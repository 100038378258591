import React from "react"
import NotificationItem from './NotificationItem';

class NotificationsChatsList extends React.Component {

  renderItems = () => {
    var components = [];

    if (this.props.notifications.length == 0) {
      return <li><div className="msg msg--info">Nie ma żadnych wiadomości</div></li>
    } else {
      for (var i = 0; i < this.props.notifications.length; i++) {
        var notification = this.props.notifications[i];
        components.push(
          <NotificationItem
            readAction={this.props.readAction}
            key={notification.id}
            {...notification}
          />
        );
      }
      return components;
    }
  }

  render() {
    return (
      <div className="notification">
        <div className="notification__header">
          <h3>Chaty</h3>
          <a title="więcej..." href="/dashboard/chats">...</a>
        </div>

        {/* <div>
          <ul className="list-incoming">
            <li className="list-incoming__item list-incoming__item--cr-unread list-incoming__item--cr">
              <div className="list-incoming__header">
                <div className="list-incoming__avatar">
                  <figure>
                    <img alt="" src={userSP} />
                  </figure>
                  <img src={arrowRight} alt="" />
                </div>
                <div className="list-incoming__time">
                  <p>10 minut</p>
                </div>
              </div>
              <div className="list-incoming__desc">
                <p>Za <strong>10 minut</strong> rozpocznie się <strong>Konsultacja prawna dla biznesu</strong> z <strong>Zbigniew</strong></p>
              </div>
              <div className="list-incoming__footer">
                <button className="btn">Zaakceptuj godzinę</button>
              </div>
            </li>

            <li className="list-incoming__item list-incoming__item--sp-unread">
              <div className="list-incoming__header">
                <div>
                  <figure>
                    <img src={userSP} alt="" />
                  </figure>
                  <img src={arrowLeft} alt="" />
                </div>
                <div>
                  <p>15 minut temu</p>
                </div>
              </div>
              <div className="list-incoming__desc">
                <p><strong>Andrzej</strong> chce zamówić <strong>Lekcja języka angielskiego z native speakerem</strong></p>
              </div>
            </li>

            <li className="list-incoming__item">
              <div className="list-incoming__header">
                <div>
                  <figure>
                    <img src={userSP} alt="" />
                  </figure>
                  <img src={rating} alt="" />
                </div>
                <div>
                  <p>1 dzień temu</p>
                </div>
              </div>
              <div className="list-incoming__desc">
                <p>Nowa <strong>ocena</strong> w <strong>Konsultacja prawna dla </strong> od <strong>Andrzej</strong></p>
              </div>
            </li>
          </ul>
        </div> */}

        <ul className="list-incoming">
          {this.renderItems()}
        </ul>
      </div>
    );
  }
}

export default NotificationsChatsList;
