import React from "react"
import PropTypes from "prop-types"
import ChatWindow from './ChatWindow';
import ChatTyping from './ChatTyping';
import merge from 'lodash/merge';
import find from 'lodash/find';
import { createConsumer } from "@rails/actioncable";
import md5 from 'md5';
import axios from 'axios';
import { MESSAGE, PROPOSITION_MESSAGE } from '../helpers/chat_helper';
import { ChatHelper } from '../helpers/chat_helper';
import classNames from 'classnames';
import { setFetchedConversations, setConversations } from '../redux/chat.actions';
import { connect } from 'react-redux';

class Chat extends React.Component {
  constructor(props) {
    super(props);
    this.attachments = {};
    this.channelObject = null;
  }

  fuploadId = (data) => {
    return md5(data);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.conversations.length == 0 && this.props.conversations.length > 0) {
      this.scrollToBottomActiveChat();
    }
  }

  componentDidMount() {
    var _this = this;
    var videoHash = this.props.isVideo ? md5(new Date().toJSON() + this.props.loggedUserHash) : null;
    this.fetchData();
    this.channelObject = createConsumer().subscriptions.create(
      {
        channel: "ChatChannel",
        chat_hash: this.props.chatHash,
        video: videoHash,
        conversation_hash: this.props.conversationHash
      },
      {
        received(data) {
          console.log("CHAT RECEIVED", data)
          _this.mergeNewMessage(data);
        }
      }
    );

    $('#fileupload').fileupload({
      dataType: 'json',
      add: function (e, data) {
        console.log("ADD TO SUBMIT", data)
        var hashId = md5(new Date().toJSON());
        _this.attachments[_this.fuploadId(data)] = hashId;
        var attachmentMessage = {
          message: data.files[0].name,
          hash_id: hashId,
          customer_id: _this.props.loggedUserHash,
          verified: null,
          message_type: "attachment",
          type: MESSAGE,
          progress: 0,
          chat_hash: _this.props.chatHash,
          conversation_id: _this.props.conversations[_this.props.activeChatIndex].id
        };
        var copiedConversations = merge([], _this.props.conversations);
        copiedConversations[_this.props.activeChatIndex] = {
          ...copiedConversations[_this.props.activeChatIndex],
          messages: copiedConversations[_this.props.activeChatIndex].messages.concat(attachmentMessage)
        }

        _this.props.setConversations(copiedConversations);
        data.formData = {
          hash_id: hashId,
          conversation_id: _this.props.conversations[_this.props.activeChatIndex].id
        };
        data.submit();
      }
    });
    $('#fileupload').on('fileuploadprogress', function (e, data) {
      _this.updateAttachmentProgress(data);
    });
    $('#fileupload').on('fileuploadfail', function (e, data) {
      var id = [_this.fuploadId(data)];
      _this.verifyMessage(_this.attachments[id], false)
    });
  }

  readNotificationOnServer = (message) => {
    this.channelObject.send({ notification_message_hash: message.hash_id });
  }

  updateAttachmentProgress = (data) => {
    var id = this.fuploadId(data);
    var percent = ((data.loaded / data.total) * 100).toFixed(2);
    var messageHash = this.attachments[id];
    var copiedConversations = merge([], this.props.conversations);
    var updateMessage = find(
      copiedConversations[this.props.activeChatIndex].messages,
      {
        'hash_id': messageHash,
        'customer_id': this.props.loggedUserHash
      });
    updateMessage.progress = percent;
  }

  verifyMessage = (hash_id, value, error = null) => {
    var { activeChatIndex, conversations } = this.props;
    var copiedConversations = merge([], conversations);
    var updateMessage = find(
      copiedConversations[activeChatIndex].messages,
      { 'hash_id': hash_id, 'customer_id': this.props.loggedUserHash }
    );
    updateMessage.verified = value;
    updateMessage.error = error;
    console.log("updateMessage", updateMessage)
    console.log("verifyMessage conversations", copiedConversations)
    this.props.setConversations(copiedConversations);
  }

  mergeNewMessage = (newMessage) => {
    var copiedConversations = merge([], this.props.conversations);
    var mergedConversations = ChatHelper.mergeNewMessage(newMessage, copiedConversations, this.props.loggedUserHash);
    if (mergedConversations != null) {
      this.props.setConversations(mergedConversations);
      this.handleScrollForNewMessage();
    }
  }

  refreshMessage = (message) => {
    // var newMessages = this.removeMessage(message.hash_id);
    // this.setState({ messages: newMessages }, () => {
    //   this.sendMessage(message.message)
    // });
  }

  // removeMessage(hash_id) {
  //   var newMessages = filter(this.state.messages, (o) => { return o.hash_id != hash_id });
  //   return newMessages;
  // }

  sendMessage = (messageText, isProposition = false) => {
    var { activeChatIndex, conversations, chatHash, myAvatar, loggedUserHash } = this.props;
    var params = {
      message: messageText,
      avatar_url: myAvatar,
      hash_id: md5(new Date().toJSON()),
      conversation_id: conversations[activeChatIndex].id,
      proposition: isProposition,
      chat_hash: chatHash
    };
    var newObjectMessage = merge({}, params);
    newObjectMessage.customer_id = loggedUserHash;
    newObjectMessage.type = isProposition ? PROPOSITION_MESSAGE : MESSAGE;
    newObjectMessage.meeting_hour = isProposition ? messageText : null;
    newObjectMessage.verified = null;
    newObjectMessage.message_type = isProposition ? null : 'normal_message';
    newObjectMessage.conversation_id = conversations[activeChatIndex].id;
    var copiedConversations = merge([], conversations);
    copiedConversations[activeChatIndex] = {
      ...copiedConversations[activeChatIndex],
      messages: copiedConversations[activeChatIndex].messages.concat(newObjectMessage)
    }
    console.log("copiedConversations", copiedConversations)
    var _this = this;
    this.props.setConversations(copiedConversations);
    axios.post(`/message/create`, params, {
      timeout: 1500,
      headers: {
        'Content-Type': 'application/json',
        'accept': 'application/json',
      }
    })
      .then(function (response) {
        console.log("done", response)
        _this.readNotificationOnServer(newObjectMessage);
        _this.verifyMessage(newObjectMessage.hash_id, true);
      })
      .catch(function (error) {
        console.log("fail", error)
        var msg = ChatHelper.getErrorMessageFromResponse(error);
        _this.verifyMessage(newObjectMessage.hash_id, false, msg);
        console.log("mmmmmmm")
      });
  }

  loadMoreChats = (e) => {
    e.preventDefault();
    var lastHashId = this.props.conversations[0].hash_id;
    this.fetchData(lastHashId);
  }

  fetchData = (lastHashId = null) => {
    var { selectedConversation, isVideo } = this.props;
    var urlParam = lastHashId ? lastHashId : '';
    var selectedConversationParam = selectedConversation ? selectedConversation : '';
    var _this = this;
    var url = "";
    if (isVideo) {
      url = `/message/fetch-data-for-video/${this.props.meetingHash}`
    } else {
      if (lastHashId) {
        url = `/message/fetch-data/${this.props.chatHash}?lhi=${urlParam}`
      } else {
        url = `/message/fetch-data/${this.props.chatHash}?conv=${selectedConversationParam}`
      }
    }
    axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        'accept': 'application/json',
      }
    })
      .then(function (response) {
        console.log(response.data)
        var lastConversationHash = response.data.last_conversation_hash;
        var conversations = response.data.data;
        _this.props.setFetchedConversations(
          conversations,
          lastConversationHash,
          lastHashId
        );
      })
      .catch(function (error) {
        console.error(error)
      });
  }

  handleScrollForNewMessage = () => {
    var css = 'window-container active';
    var container = document.getElementsByClassName(css)[0];
    var canScroll = (container.scrollHeight - (container.scrollTop + container.clientHeight)) < 100;
    if (canScroll) {
      this.scrollToBottomActiveChat();
    }
  }

  scrollToBottomActiveChat = () => {
    var css = '#chat-conversation';
    $(css).animate({ scrollTop: $(css).prop("scrollHeight") }, 300);
  }

  acceptMeetingHour = (propositionMessage) => {
    var params = {
      hash_id: propositionMessage.hash_id
    }
    axios.post(`/message/accept-meeting-hour`, params, {
      timeout: 5000,
      headers: {
        'Content-Type': 'application/json',
        'accept': 'application/json',
      }
    })
      .then(function (response) {
        console.log("done", response)
      })
      .catch(function (error) {
        var msg = ChatHelper.getErrorMessageFromResponse(error);
        if (msg) {
          alert(msg);
        } else {
          alert('Sprawdź połączenie i spróbuj ponownie')
        }
        console.log("fail", error)
      });
  }

  showLoadMoreButton = () => {
    var { conversations, lastConversationHash } = this.props;
    if (lastConversationHash && conversations.length > 0) {
      var lastConversation = conversations[0];
      return lastConversation.hash_id != lastConversationHash;
    } else {
      return false;
    }
  }

  render() {
    var { isServiceProvider } = this.props;
    var css = classNames({
      'chat-conversation': true,
      'chat-conversation--sp': isServiceProvider
    });
    if (this.props.conversations.length > 0) {
      var activeConversationId = this.props.conversations[this.props.activeChatIndex].id;
    } else {
      var activeConversationId = null;
    }

    return (
      <React.Fragment>
        <div id="chat-conversation" className={css}>
          {this.showLoadMoreButton() &&
            <p style={{ textAlign: 'center' }}>
              <a onClick={this.loadMoreChats} href="">
                Załaduj archiwalne czaty
              </a>
            </p>
          }
          {this.props.conversations.map((conversation, index) => (
            <ChatWindow
              key={conversation.id}
              serviceName={conversation.service_name}
              servicePrice={conversation.service_price}
              refreshMessage={this.refreshMessage}
              conversation={conversation}
              expanded={this.props.activeChatIndex == index}
              indexConversation={index}
              acceptMeetingHour={this.acceptMeetingHour}
              readNotificationOnServer={this.readNotificationOnServer}
            />
          ))}
        </div>
        <ChatTyping
          scrollToBottomActiveChat={this.scrollToBottomActiveChat}
          sendMessage={this.sendMessage}
          conversations={this.props.conversations}
          conversationId={activeConversationId}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isVideo: state.chat.isVideo,
    activeChatIndex: state.chat.activeChatIndex,
    chatHash: state.chat.chatHash,
    conversationHash: state.chat.conversationHash,
    meetingHash: state.chat.meetingHash,
    conversations: state.chat.conversations,
    lastConversationHash: state.chat.lastConversationHash,
    openSelectedConversation: state.chat.openSelectedConversation,
    selectedConversation: state.chat.selectedConversation,
    loggedUserHash: state.chat.loggedUserHash,
    myAvatar: state.chat.myAvatar,
    isServiceProvider: state.chat.isServiceProvider
  };
};

export default
  connect(
    mapStateToProps,
    { setFetchedConversations, setConversations }
  )(Chat);
