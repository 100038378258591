import React from "react"
import classNames from 'classnames';
import moment from 'moment';
import arrowLeft from '../../../assets/images/arrows/selling.svg';
import arrowRight from '../../../assets/images/arrows/buying.svg';
import { NotificationHelper } from '../../helpers/notification_helper';

class NotificationItem extends React.Component {

  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
    moment.locale('pl');
  }

  componentDidMount() {
    this.updateTimeAgo();
  }

  componentDidUpdate() {
    this.updateTimeAgo();
  }

  handleOnClick = (e) => {
    var { id, readAction } = this.props;
    readAction(id);
  }

  updateTimeAgo = () => {
    var { created_at } = this.props;
    var timeAgo = moment(created_at).fromNow();
    var jqueryObj = $(this.containerRef.current).find('.list-incoming__time');
    if (jqueryObj.length) {
      jqueryObj.html(`<p>${timeAgo}</p>`);
    }
  }

  render() {
    var helper = new NotificationHelper(this.props);
    var { html_message, readed_at, ordering_side, created_at } = this.props;
    var css = classNames(
      'list-incoming__item',
      {
        'list-incoming__item--cr-unread': helper.isBuying() && readed_at == null,
        'list-incoming__item--cr': helper.isBuying(),
        'list-incoming__item--sp-unread': helper.isSelling() && readed_at == null,
        'list-incoming__item--sp': helper.isSelling(),
      }
    );
    var arrowImage = arrowRight;
    if (helper.isSelling()) {
      arrowImage = arrowLeft;
    }
    return (
      <li ref={this.containerRef} className={css} onClick={this.handleOnClick}>
        <div dangerouslySetInnerHTML={{ __html: html_message }} />
      </li>
    );
  }
}

export default NotificationItem;
