import React from "react"
import PropTypes from "prop-types"
import classNames from 'classnames';
import { getAvatarUrl } from '../helpers/common_helper';
import moment from 'moment';
import ChatsTime from './chats/ChatsTime';

class ChatMessage extends React.Component {

  render() {
    var { isServiceProvider, isMyMessage, message } = this.props;
    var markAsServiceProvider = false;
    if (isMyMessage) {
      markAsServiceProvider = isServiceProvider;
    } else {
      markAsServiceProvider = !isServiceProvider;
    }

    var css = classNames({
      'chat-msg': true,
      'chat-msg--sp': markAsServiceProvider,
      'chat-msg--cr': !markAsServiceProvider
    });

    return (
      <div className={css}>
        <div className="chat-msg__content">
          <ChatsTime date={message.created_at} isMyMessage={isMyMessage} />
          <figure>
            <img alt={message.name} src={getAvatarUrl(message.avatar_url)} />
          </figure>
          <div className="chat-msg__answer">
            <p>{message.message}</p>
          </div>
        </div>
      </div>
    );
  }
}

ChatMessage.propTypes = {
  message: PropTypes.object
};

export default ChatMessage
