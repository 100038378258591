import React from "react"
import PropTypes from "prop-types"
import ChatMessage from "./ChatMessage"
import ChatCommonMessage from "./ChatCommonMessage";
import ChatAttachmentMessage from "./ChatAttachmentMessage";
import ChatPropositionMessage from "./ChatPropositionMessage";
import { ChatHelper } from '../helpers/chat_helper';
import ChatAnswers from './ChatAnswers';
import moment from 'moment';
import { displayNiceAmount } from '../helpers/common_helper';
import { getAvatarUrl } from '../helpers/common_helper';
import ChatAcceptedMessage from './ChatAcceptedMessage';
import ChatSurchargeMessage from './ChatSurchargeMessage';
import ChatPaidSurchargeMessage from './ChatPaidSurchargeMessage';
import ChatRefundMessage from './ChatRefundMessage';
import { setActiveChatIndex } from '../redux/chat.actions';
import { connect } from 'react-redux';

class ChatWindow extends React.Component {

  constructor(props) {
    super(props);
  }

  displayMessages = () => {
    var { conversation, loggedUserHash, readNotificationOnServer, refreshMessage,
      isServiceProvider, expanded, acceptMeetingHour, myAvatar } = this.props;
    if (!expanded) {
      return null;
    }

    var components = [];
    for (var i = 0; i < conversation.messages.length; i++) {
      var message = conversation.messages[i];
      var isMyMessage = message.customer_id == this.props.loggedUserHash;
      var commonProps = {
        key: `com-${message.hash_id}`,
        message: message,
        isMyMessage: isMyMessage,
        readNotificationOnServer: readNotificationOnServer,
        refreshMessage: refreshMessage
      };
      var messageProps = {
        key: message.hash_id,
        message: message,
        isMyMessage: isMyMessage,
        loggedUserHash: loggedUserHash,
        isServiceProvider: isServiceProvider,
        myAvatar: myAvatar
      };

      if (ChatHelper.isMessage(message)) {
        components.push(
          <ChatCommonMessage {...commonProps}>
            <ChatMessage {...messageProps} />
          </ChatCommonMessage>
        );
      }
      else if (ChatHelper.isAttachment(message)) {
        components.push(
          <ChatCommonMessage {...commonProps}>
            <ChatAttachmentMessage {...messageProps} />
          </ChatCommonMessage>
        );
      } else if (ChatHelper.isPropositionMessage(message)) {
        components.push(
          <ChatCommonMessage {...commonProps}>
            <ChatPropositionMessage
              isFirstPropositionMessage={conversation.fist_proposition_message_hash_id == message.hash_id}
              lastPropositionMessageHashId={conversation.last_proposition_message_hash_id == message.hash_id}
              isAcceptedTime={conversation.accepted_time != null}
              acceptMeetingHour={acceptMeetingHour}
              {...messageProps}
            />
          </ChatCommonMessage>
        );
      } else if (ChatHelper.isAcceptedMessage(message)) {
        components.push(
          <ChatCommonMessage {...commonProps} isSystemMessage>
            <ChatAcceptedMessage {...messageProps} />
          </ChatCommonMessage>
        );
      } else if (ChatHelper.isSurchargeMessage(message)) {
        components.push(
          <ChatCommonMessage {...commonProps} isSystemMessage>
            <ChatSurchargeMessage {...messageProps} />
          </ChatCommonMessage>
        );
      } else if (ChatHelper.isPaidSurchargeMessage(message)) {
        components.push(
          <ChatCommonMessage {...commonProps} isSystemMessage>
            <ChatPaidSurchargeMessage {...messageProps} />
          </ChatCommonMessage>
        );
      } else if (ChatHelper.isRefundMessage(message)) {
        components.push(
          <ChatCommonMessage {...commonProps} isSystemMessage>
            <ChatRefundMessage {...messageProps} />
          </ChatCommonMessage>
        );
      }
    }
    return components;
  }

  handleExpandChatWindow = (e) => {
    var { indexConversation } = this.props;
    if (indexConversation == this.props.activeChatIndex) {
      var activeChatIndex = null;
    } else {
      var activeChatIndex = indexConversation;
    }
    this.props.setActiveChatIndex(activeChatIndex);
  }

  render() {
    var { serviceName, servicePrice, expanded, conversation,
      isServiceProvider } = this.props;
    var windowClassName = "window-container";
    if (expanded) {
      windowClassName = windowClassName + ' active';
    }
    var createdAt = moment(conversation.order_created_at);
    return (
      <div className={windowClassName}>
        <div className="chat-service">
          <div className="chat-service__header" onClick={this.handleExpandChatWindow}>
            <p className="chat-service__title">
              {serviceName}
              <i className="ic ic--dropdown"></i>
            </p>

            <ul className="chat-service__about">
              <li><i className="ic ic--calendar"></i>{createdAt.format('DD.MM.YYYY')}</li>
              <li><i className="ic ic--clock"></i>złożono {createdAt.format('HH:mm')}</li>
              <li><i className="ic ic--cash"></i>{displayNiceAmount(servicePrice)} zł/h</li>
            </ul>
          </div>
        </div>

        <div style={{ display: expanded ? 'block' : 'none' }}>
          <p className="chat-service__term">
            Termin wykonania usługi: <strong>{conversation.service_start_type}</strong>
          </p>

          <ChatAnswers answers={conversation.answers} />

          {(conversation.messages.length == 0 && isServiceProvider) &&
            <p className="chat-suggest">
              Zaproponuj <a href="#chat-suggest-time">godzinę spotkania</a> lub {' '}
              <a href="#chat-send-message">napisz wiadomość</a>
            </p>
          }

          {this.displayMessages()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    activeChatIndex: state.chat.activeChatIndex,
    chatHash: state.chat.chatHash,
    isServiceProvider: state.chat.isServiceProvider,
    loggedUserHash: state.chat.loggedUserHash,
    myAvatar: state.chat.myAvatar
  };
};

export default
  connect(
    mapStateToProps,
    {setActiveChatIndex}
  )(ChatWindow);
