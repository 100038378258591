import React from "react"
import PropTypes from "prop-types"
import classNames from 'classnames';
import moment from 'moment';
import { getAvatarUrl } from '../helpers/common_helper';
import ChatsTime from './chats/ChatsTime';

class ChatPropositionMessage extends React.Component {

  handleClickAcceptButton = (e) => {
    e.preventDefault();
    this.props.acceptMeetingHour(this.props.message);
  }

  renderMyMessage = () => {
    var { isFirstPropositionMessage } = this.props;
    var { meeting_hour, avatar_url, name, created_at } = this.props.message;
    var tommorowText = '';
    if (this.isTommorow(meeting_hour)) {
      tommorowText = ' jutro ';
    }

    var textMeetingHour = moment(meeting_hour).format('HH:mm')
    if (isFirstPropositionMessage) {
      return (
        <div className="chat-msg-user chat-msg-user--sp">
          <ChatsTime date={created_at} isMyMessage={false} />
          <figure>
            <img alt={name} src={getAvatarUrl(avatar_url)} />
          </figure>
          <p>Zaproponowałeś spotkanie {tommorowText} o <strong>{textMeetingHour}</strong></p>
        </div>
      );
    } else {
      return (
        <div className="chat-msg-user chat-msg-user--sp">
          <ChatsTime date={created_at} isMyMessage={false} />
          <figure>
            <img alt={name} src={getAvatarUrl(avatar_url)} />
          </figure>
          <p>Zmieniłeś godzinę spotkania na {tommorowText} <strong>{textMeetingHour}</strong></p>
        </div>
      );
    }
  }

  isTommorow = (date) => {
    var tommorow = moment().add(1, 'days')
    return tommorow.isSame(date, 'day');
  }

  renderInterlocutorMessage = () => {
    var { isFirstPropositionMessage, lastPropositionMessageHashId,
      isAcceptedTime } = this.props;
    var { meeting_hour, name, avatar_url, created_at } = this.props.message;
    var textMeetingHour = moment(meeting_hour).format('HH:mm')
    var tommorowText = '';
    if (this.isTommorow(meeting_hour)) {
      tommorowText = ' jutro ';
    }
    var css = classNames({
      'chat-msg-user': true,
      'chat-msg-user--button': lastPropositionMessageHashId && !isAcceptedTime
    });

    if (isFirstPropositionMessage) {
      return (
        <div className={css}>
          <ChatsTime date={created_at} isMyMessage={false} />
          <figure>
            <img alt={name} src={getAvatarUrl(avatar_url)} />
          </figure>
          <p>
            <strong>{name}</strong> proponuje spotkanie {tommorowText} o: <strong>{textMeetingHour}</strong>
            {(lastPropositionMessageHashId && !isAcceptedTime) && (
              <a onClick={this.handleClickAcceptButton} className="btn">Zaakceptuj godzinę</a>
            )}
          </p>
        </div>
      );
    } else {
      return (
        <div className="chat-msg-user chat-msg-user--button">
          <ChatsTime date={created_at} isMyMessage={false} />
          <figure>
            <img alt={name} src={getAvatarUrl(avatar_url)} />
          </figure>
          <p>
            <strong>{name}</strong> zmienił proponowaną porę spotkania na {tommorowText} <strong>{textMeetingHour}</strong>
            {(lastPropositionMessageHashId && !isAcceptedTime) && (
              <a onClick={this.handleClickAcceptButton} className="btn">Zaakceptuj godzinę</a>
            )}
          </p>
        </div>
      );
    }
  }

  render() {
    var { isMyMessage } = this.props;
    return (
      <React.Fragment>
        { isMyMessage && (
          this.renderMyMessage()
        )}
        { !isMyMessage && (
          this.renderInterlocutorMessage()
        )}
      </React.Fragment>
    );
  }
}

ChatPropositionMessage.propTypes = {
  message: PropTypes.object
};

export default ChatPropositionMessage;
