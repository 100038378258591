import React from "react"
import findIndex from 'lodash/findIndex';
import { createConsumer } from "@rails/actioncable";
import NotificationMain from './notifications/NotificationMain';
import NotificationPanel from './notifications/NotificationPanel';
import NotificationList from './notifications/NotificationsList';
import NotificationsChatsList from "./notifications/NotificationsChatsList";

class Notifications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showNotificationsList: false,
      showChatsList: false,
      notifications: [],
      chatNotifications: [],
      unreadNotifications: false,
      unreadChatNotifications: false
    }
    this.channelObject = null;
    this.containerRef = React.createRef();
  }

  componentDidMount() {
    var _this = this;
    this.channelObject = createConsumer().subscriptions.create("NotificationChannel", {
      received(data) {
        console.log("data WS", data)
        if (data.refresh) {
          this.perform("last_notifications");
        } else if (data.initial_data) {
          _this.setupBasicData(data);
        } else {
          _this.parseData(data);
          if (data.notification.chat_type == "proposition" || data.notification.chat_type == "accepted") {
            this.perform("last_notifications");
          }
        }
      },
      connected() {
        this.perform("last_notifications");
      }
    });

    // handle list in panel ;)
    $('a.notification-click').click(function (e) {
      e.preventDefault();
      var id = $(this).data('id')
      _this.readAction(id);
      $(this).parent().html('<span class="notifi-status notifi-status--read" title="Przeczytano"></span>');
    });

    // close notifications box when click outside
    document.addEventListener('mousedown', this.handleClickOutside);

    // autorefresh after 30s
    window.setInterval(() => this.forceUpdate(), 30 * 1000);
  }

  handleClickOutside = () => {
    if (this.state.showChatsList || this.state.showNotificationsList) {
      if (this.containerRef && !this.containerRef.current.contains(event.target)) {
        this.setState({ showChatsList: false, showNotificationsList: false })
      }
    }
  }

  setupBasicData = (data) => {
    this.setState({
      notifications: data.initial_data.notifications,
      chatNotifications: data.initial_data.chat_notifications,
      unreadNotifications: data.initial_data.unread_notifications,
      unreadChatNotifications: data.initial_data.unread_chat_notifications
    })
  }

  parseData = (data) => {
    var newNotifications = [...this.state.notifications];
    var newChatNotifications = [...this.state.chatNotifications];
    var newState = null;
    var notification = data.notification;
    if (notification.kind == 'chat') {
      newChatNotifications = this.updateList(newChatNotifications, notification);
      newState = {
        chatNotifications: newChatNotifications,
        unreadNotifications: data.has_unread_notifications,
        unreadChatNotifications: data.has_unread_chat_notifications
      }
    } else {
      newNotifications = this.updateList(newNotifications, notification);
      newState = {
        notifications: newNotifications,
        unreadNotifications: data.has_unread_notifications,
        unreadChatNotifications: data.has_unread_chat_notifications
      }
    }
    this.setState(newState);
  }

  updateList = (notificationList, notification) => {
    if (notificationList.length == 0) {
      notificationList.push(notification);
    } else {
      var existingNotificationIndex = findIndex(
        notificationList,
        function (o) {
          return o.id === notification.id;
        })
      if (existingNotificationIndex > -1) {
        notificationList.splice(existingNotificationIndex, 1, notification);
        // update notification
      } else {
        // add new notification to beginning of array and remove last one
        var totalElements = notificationList.unshift(notification);
        if (totalElements > 20) {
          notificationList.pop();
        }
      }
    }
    return notificationList;
  }

  readAction = (id) => {
    this.channelObject.send({ id })
  }

  handleClickNotificationIcon = (e) => {
    e.preventDefault();
    this.setState({
      showNotificationsList: !this.state.showNotificationsList,
      showChatsList: false
    });
  }

  handleClickChatIcon = (e) => {
    e.preventDefault();
    this.setState({
      showChatsList: !this.state.showChatsList,
      showNotificationsList: false
    });
  }

  render() {
    var headerComponent = null;
    var setupProps = {
      showNotificationsList: this.state.showNotificationsList,
      showChatsList: this.state.showChatsList,
      unreadNotifications: this.state.unreadNotifications,
      unreadChatNotifications: this.state.unreadChatNotifications,
      handleClickNotificationIcon: this.handleClickNotificationIcon,
      handleClickChatIcon: this.handleClickChatIcon,
      logoutPath: this.props.logoutPath
    }
    if (this.props.isPanel) {
      headerComponent = (<NotificationPanel {...setupProps} />);
    } else {
      headerComponent = <NotificationMain {...setupProps} />;
    }

    var content = null;
    if (this.state.showNotificationsList) {
      content = (<NotificationList
        notifications={this.state.notifications}
        listUrl={this.props.listUrl}
        readAction={this.readAction}
      />);
    } else if (this.state.showChatsList) {
      content = (<NotificationsChatsList
        notifications={this.state.chatNotifications}
        readAction={this.readAction}
      />);
    }

    return (
      <div ref={this.containerRef}>
        {headerComponent}
        {content}
      </div>
    );
  }
}

export default Notifications;
