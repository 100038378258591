import React from "react"
import PropTypes from "prop-types"
import classNames from 'classnames';
import moment from 'moment';
import { getAvatarUrl } from '../helpers/common_helper';
import ChatsTime from './chats/ChatsTime';

class ChatAcceptedMessage extends React.Component {

  render() {
    var message = this.props.message;
    var textMeetingHour = moment(message.meeting_hour).format('HH:mm')

    return (
      <div className="chat-msg-user">
        <ChatsTime date={message.created_at} isMyMessage={false} />
        <figure>
          <img alt={message.name} src={getAvatarUrl(message.avatar_url)} />
        </figure>
        <p className="mb-10">
          <strong>{message.name}</strong> zaakceptował spotkanie o godzinie <strong>{textMeetingHour}</strong>
        </p>
        <p>Potwierdzenie zostało wysłane na adres e-mail.</p>
        <p dangerouslySetInnerHTML={{ __html: message.message }} />
      </div>
    );
  }
}

ChatAcceptedMessage.propTypes = {
  message: PropTypes.object
};

export default ChatAcceptedMessage;
