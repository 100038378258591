import React from "react"
import PropTypes from "prop-types"

class ChatCommonMessage extends React.Component {

  constructor(props) {
    super(props);
    this.messageRef = React.createRef();
    this.state = { markAsVisible: false };
    this.observer = null;
  }

  componentDidMount() {
    if (!this.props.message.readed_at) {
      var options = {
        root: document.querySelector('#chat-container'),
        threshold: 1.0
      }
      this.observer = new IntersectionObserver(this.handleObserver, options);
      this.observer.observe(this.messageRef.current);
    }
  }

  componentWillUnmount() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  handleObserver = (entries, observer) => {
    var isIntersecting = entries[0].isIntersecting;
    if (this.state.markAsVisible == false && isIntersecting) {
      this.props.readNotificationOnServer(this.props.message);
      this.observer.disconnect();
    }
  }

  // handleOnMouseEnter = () => {
  //   $(this.messageRef.current).find(".chat-message-date").show();
  // }

  // handleOnMouseLeave = () => {
  //   $(this.messageRef.current).find(".chat-message-date").hide();
  // }

  handleRefreshMessage = (e) => {
    e.preventDefault();
    this.props.refreshMessage(this.props.message);
  }

  failedText = () => {
    var { message_type, error } = this.props.message;
    var text = 'Nie wysłano wiadomości. Kliknij aby ponowić';
    if (error) {
      text = error;
    }
    if (message_type == "attachment") {
      return (
        <div className="chat-msg--warning">
          <p>Nie wysłano. Spróbuj ponownie</p>
        </div>
      );
    } else {
      return (
        <div className="chat-msg--warning">
          <p onClick={this.handleRefreshMessage}>
            {text}
          </p>
        </div>
      );
    }
  }

  render() {
    var { verified } = this.props.message;
    return (
      <div ref={this.messageRef}>
        {this.props.children}
        {verified === false && this.failedText()}
      </div>
    );
  }
}

ChatCommonMessage.propTypes = {
  message: PropTypes.object
};

export default ChatCommonMessage;
