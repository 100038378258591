export class NotificationHelper {
  constructor(notificationObject) {
    this.notification = notificationObject;
  }

  isSelling = () => {
    return this.notification.ordering_side == 'selling';
  }

  isBuying = () => {
    return this.notification.ordering_side == 'buying';
  }

  isCreateOrder = () => {
    return this.notification.chat_type == 'create_order';
  }

}