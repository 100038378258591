import React from "react"
import classNames from 'classnames';
import moment from 'moment';
import visaImg from '../../../assets/images/logo/visa.svg';
import mastercardImg from '../../../assets/images/logo/mastercard.svg';

class PaymentComponent extends React.Component {

  renderItemCard = (card) => {
    var checked = card.id == this.props.defaultPaymentMethodId;
    var inputId = `order_payment_method_id_${card.id.toLowerCase()}`;
    var expYear = card.card.exp_year.toString();
    return (
      <li className="list-cards__item" key={card.id} >
        <input
          type="radio"
          name="order[payment_method_id]"
          value={card.id}
          defaultChecked={checked}
          id={inputId}
        />
        <div className="list-cards__desc">
          <label htmlFor={inputId}>
            <span className="list-cards__number">**** **** **** {card.card.last4}</span>
            {this.renderBrandImage(card)}
            <span className="list-cards__date">
              {card.card.exp_month}/{expYear.substr(-2)}
            </span>
          </label>
        </div>
      </li>
    );
  }

  renderBrandImage = (card) => {
    if (card.card.brand == 'visa') {
      return (<img className="list-cards__brand" src={visaImg} alt="visa" />);
    } else if (card.card.brand == 'mastercard') {
      return (<img className="list-cards__brand" src={mastercardImg} alt="mastercard" />);
    } else {
      return (<span className="list-cards__brand">{card.card.brand}</span>);
    }
  }

  render() {
    var cards = this.props.cards;
    var cardsComponents = [];
    for (var i = 0; i < cards.length; i++) {
      cardsComponents.push(this.renderItemCard(cards[i]));
    }
    return (
      <div>
        <form id="surcharge-form" action="" method="post">
          {cards.length > 0 && (
            <React.Fragment>
              <p>Twoje zapisane karty:</p>
              <ul className="list-cards">
                {cardsComponents}
              </ul>
            </React.Fragment>
          )}

          {cards.length > 0 && (
            <a
              id="add-card-button"
              style={{ marginBottom: "25px" }}
              className="btn btn--outline btn--outline-gray"
            >
              <i className="ic ic--add"></i>
            Dodaj nową kartę
            </a>
          )}

          <div
            id="add-card-container"
            style={{ display: cards.length > 0 ? 'none' : 'block' }}
            className="form-inputs"
          >
            <div className="field mb-50">
              <p>Dane karty:</p>
              <div className="row-fluid">
                <div id="card-number"></div>
              </div>

              <div className="grid no-padd">
                <div className="col--6">
                  <div className="row-fluid">
                    <div id="card-expiry"></div>
                  </div>
                </div>

                <div className="col--6">
                  <div className="row-fluid">
                    <div id="card-cvc"></div>
                  </div>
                </div>
              </div>

              <div className="card-error" role="alert">
                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17">
                  <path className="base" fill="#000" d="M8.5,17 C3.80557963,17 0,13.1944204 0,8.5 C0,3.80557963 3.80557963,0 8.5,0 C13.1944204,0 17,3.80557963 17,8.5 C17,13.1944204 13.1944204,17 8.5,17 Z" />
                  <path className="glyph" fill="#FFF" d="M8.5,7.29791847 L6.12604076,4.92395924 C5.79409512,4.59201359 5.25590488,4.59201359 4.92395924,4.92395924 C4.59201359,5.25590488 4.59201359,5.79409512 4.92395924,6.12604076 L7.29791847,8.5 L4.92395924,10.8739592 C4.59201359,11.2059049 4.59201359,11.7440951 4.92395924,12.0760408 C5.25590488,12.4079864 5.79409512,12.4079864 6.12604076,12.0760408 L8.5,9.70208153 L10.8739592,12.0760408 C11.2059049,12.4079864 11.7440951,12.4079864 12.0760408,12.0760408 C12.4079864,11.7440951 12.4079864,11.2059049 12.0760408,10.8739592 L9.70208153,8.5 L12.0760408,6.12604076 C12.4079864,5.79409512 12.4079864,5.25590488 12.0760408,4.92395924 C11.7440951,4.59201359 11.2059049,4.59201359 10.8739592,4.92395924 L8.5,7.29791847 L8.5,7.29791847 Z" />
                </svg>
                <span className="message"></span>
              </div>

              <label htmlFor="order_save_card">
                Zapisz kartę
            </label>
              <input className="boolean optional" type="checkbox" value="1" name="order[save_card]" id="save-card" />
            </div>

            <div className="protip">
              <i className="ic ic--protip"></i>
              <p>Wraz z wysłaniem zlecenia nastąpi blokada środków na koncie. Kwota zostanie pobrana dopiero w momencie rozpoczęcia usługi</p>
            </div>

          </div>
          {/* order[save_card] */}
          <input type="hidden" name="order[payment_intent_id]" id="order_payment_intent_id" />
          <input type="submit" className="btn btn--expanded" id="submit-button" value="Zapłać" />
        </form>
      </div>
    );
  }
}

export default PaymentComponent;
