import defaultAvatar from '../../assets/images/pic/service_provider.svg';

export function displayNiceAmount(value) {
  var valueIntCents = parseInt(value);
  var money = (valueIntCents / 100).toFixed(2);
  return money;
}

export function getAvatarUrl(avatarUrl) {
  if (avatarUrl) {
    return avatarUrl;
  } else {
    return defaultAvatar;
  }
}