// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
// require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

import chatReducer from '../redux/chat.reducer';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import _ from 'lodash';

// react:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

const combinedReducers = combineReducers(
  {
    chat: chatReducer
  },
);

console.log("storeInitial", window.storeInitial)
let storeInitApp = {};
if (window.storeInitial) {
  storeInitApp = window.storeInitial;
}
let chatInitial = {
  chat: {
    conversations: [],
    lastConversationHash: null,
    activeChatIndex: null,
    openSelectedConversation: false,
    selectedConversation: null
  }
};
let storeInit = _.merge({}, storeInitApp, chatInitial);

const store = createStore(
  combinedReducers,
  storeInit,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
window.appStore = store;
