import React from "react"
import classNames from 'classnames';

class NotificationPanel extends React.Component {

  render() {
    return (
      <ul className="header__links">
        <li>
          <a
            className={classNames({
              'links-unread': this.props.unreadNotifications,
              'active': this.props.showNotificationsList
            })}
            onClick={this.props.handleClickNotificationIcon}
            href=""
            title="Powiadomienia"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 22.817 24.076"
              width="23"
              height="25"
            >
              <path
                data-name="Path 1905"
                d="M22.817 20.458l-3.576-6.652V9.685a8.027 8.027 0 00-7-7.951V-.003h-2.023v1.737a8.027 8.027 0 00-7.005 7.951v4.142L.005 20.455h7.2a4.05 4.05 0 008.053 0zM5.231 14.293V9.685a5.994 5.994 0 0111.987 0v4.629l2.214 4.119h-16.2zm5.993 7.759a2.03 2.03 0 01-1.979-1.594h3.959a2.03 2.03 0 01-1.98 1.594z"
              />
            </svg>
          </a>
        </li>

        <li>
          <a
            className={classNames({
              'links-unread': this.props.unreadChatNotifications,
              'active': this.props.showChatsList
            })}
            onClick={this.props.handleClickChatIcon}
            href=""
            title="Wiadomości"
          >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24.2 24.1"
            width="25"
            height="25"
          >
            <path
              className="prefix__st0"
              d="M8.2 24.1L4.6 19h-.7C1.8 19 0 17.3 0 15.1V3.9C0 1.8 1.8 0 3.9 0h16.4c2.2 0 3.9 1.8 3.9 3.9v11.2c0 2.2-1.8 3.9-3.9 3.9H12l-3.8 5.1zM3.9 2C2.9 2 2 2.9 2 3.9v11.2c0 1 .8 1.9 1.9 1.9h1.7l2.6 3.7L11 17h9.3c1 0 1.9-.8 1.9-1.9V3.9c0-1-.8-1.9-1.9-1.9H3.9z"
            />
            <g transform="translate(4.511 7.468)">
              <circle className="prefix__st0" cx={1.8} cy={1.8} r={1.8} />
              <circle className="prefix__st0" cx={7.6} cy={1.8} r={1.8} />
              <circle className="prefix__st0" cx={13.5} cy={1.8} r={1.8} />
            </g>
          </svg>
          </a>
        </li>

        <li>
          <a
            href={this.props.logoutPath}
            title="Wyloguj się"
            rel="nofollow"
            data-method="delete"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 25 19.1"
              width="24"
              height="24"
            >
              <path
                d="M24.7 10.4l-8.3 8.3c-.7.7-2 .2-2-.8v-4.8H7.5c-.7 0-1.2-.5-1.2-1.2V7.1c0-.6.6-1.1 1.2-1.1h6.7V1.2c0-1.1 1.3-1.6 2-.8l8.3 8.3c.6.4.6 1.2.2 1.7zM9.5 18.5v-2c0-.3-.3-.6-.6-.6H4.8c-.9 0-1.6-.7-1.6-1.6V4.8c0-.9.7-1.6 1.6-1.6H9c.3 0 .6-.3.6-.6v-2C9.5.3 9.3 0 8.9 0H4.8C2.1 0 0 2.1 0 4.8v9.5C0 16.9 2.1 19 4.8 19H9c.3 0 .5-.2.5-.5z"
              />
            </svg>
          </a>
        </li>
      </ul>
    );
  }
}

export default NotificationPanel;
