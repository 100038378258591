import _ from 'lodash';
import {
  SET_FETCHED_CONVERSATIONS,
  SET_CONVERSATIONS,
  SET_ACTIVE_CHAT_INDEX

} from './actionTypes'

let initialState = {};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_FETCHED_CONVERSATIONS:
      var ns = _.merge({}, state);
      var selectedConversationIndex = null;
      action.conversations.forEach((conversation_item, index) => {
        if (ns.selectedConversation) {
          if (ns.selectedConversation == conversation_item.hash_id) {
            selectedConversationIndex = index;
          }
        }
        conversation_item.messages.forEach((message, index_message) => {
          message.verified = true;
        });
      });
      if (action.lastHashId) {
        ns.conversations = action.conversations.concat(ns.conversations);
        ns.lastConversationHash = action.lastConversationHash;
        ns.activeChatIndex = ns.activeChatIndex + action.conversations.length;
      } else {
        var indexToSet = action.conversations.length - 1;
        if (selectedConversationIndex != null) {
          indexToSet = selectedConversationIndex;
        }
        ns.conversations = action.conversations;
        ns.lastConversationHash = action.lastConversationHash;
        ns.activeChatIndex = indexToSet;
      }
      return ns;

    case SET_ACTIVE_CHAT_INDEX:
      return _.merge({}, state, { activeChatIndex: action.activeChatIndex });

    case SET_CONVERSATIONS:
      var ns = _.merge({}, state);
      ns.conversations = action.conversations;
      return ns;

    default:
      return state;
  }
}


