import {
  SET_FETCHED_CONVERSATIONS,
  SET_CONVERSATIONS,
  SET_ACTIVE_CHAT_INDEX,

 } from './actionTypes';

export const setFetchedConversations = (conversations, lastConversationHash, lastHashId) => {
  return {
    type: SET_FETCHED_CONVERSATIONS,
    conversations,
    lastConversationHash,
    lastHashId
  }
}

export const setActiveChatIndex = (activeChatIndex) => {
  return {
    type: SET_ACTIVE_CHAT_INDEX,
    activeChatIndex,
  }
}

export const setConversations = (conversations) => {
  return {
    type: SET_CONVERSATIONS,
    conversations,
  }
}
