import React from "react"
import classNames from 'classnames';
import moment from 'moment';

class ChatTime extends React.Component {


  render() {
    var { date, isMyMessage } = this.props;
    var createdAtText = moment(date).format("HH:mm");
    var styleInline = {};
    if (isMyMessage != null) {
      styleInline['float'] = isMyMessage ? 'left' : 'right';
    }
    return (
      <p
        style={styleInline}
        className="chat-message-date"
      >
        {createdAtText}
      </p>
    );
  }
}

export default ChatTime;
