import React from "react"
import axios from 'axios';
import { ChatHelper } from '../../helpers/chat_helper';

class SurchargeModalContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: '', error: null };
  }

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.value < 5) {
      this.setState({ error: 'Minimalna kwota to 5zł' });
      return false;
    } else {
      var _this = this;
      var params = {
        conversation_id: this.props.conversationId,
        surcharge: true,
        chat_hash: this.props.chatHash,
        amount: this.state.value,
      };
      axios.post(`/message/create`, params, {
        timeout: 1500,
        headers: {
          'Content-Type': 'application/json',
          'accept': 'application/json',
        }
      })
        .then(function (response) {
          console.log("done", response)
          _this.setState({ value: '' });
          _this.props.closeModal();
        })
        .catch(function (error) {
          console.log("fail", error)
          var msg = ChatHelper.getErrorMessageFromResponse(error);
          alert(msg);
        });
    }
  }

  handleInputChange = (e) => {
    this.setState({ value: e.target.value, error: null });
  }

  render() {
    return (
      <React.Fragment>
        <p>Wyślij prośbę o dopłatę za usługę {this.props.serviceName}</p>
        <form onSubmit={this.handleSubmit} method="post">
          <fieldset>
            <input
              type="number"
              value={this.state.value}
              onChange={this.handleInputChange}
              placeholder="Podaj kwotę (zł)"
              step="1"
            />
            {this.state.error && (
              <p className="modal-error">{this.state.error}</p>
            )}
          </fieldset>
          <input type="submit" className="btn btn--expanded" value="Wyślij" />
        </form>
      </React.Fragment>
    );
  }
}

export default SurchargeModalContent;
