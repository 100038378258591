import React from "react"
import PropTypes from "prop-types"
import { getAvatarUrl } from '../helpers/common_helper';
import ChatsTime from './chats/ChatsTime';
import { ChatHelper } from '../helpers/chat_helper';
import { displayNiceAmount } from '../helpers/common_helper';
import ChatsModal from './chats/ChatsModal';
import RefundModalContent from './chats/RefundModalContent';

class ChatRefundMessage extends React.Component {
  constructor(props) {
    super(props);
    this.refundModal = React.createRef();
    this.state = {
      transactionHashId: null
    }
  }

  handleClickMakeRefund = (e, transactionHashId) => {
    e.preventDefault();
    this.setState({ transactionHashId }, () => {
      this.refundModal.current.open();
    });
  }

  renderServiceProviderMessage = () => {
    var { transaction_amount, avatar_url, name, created_at, transaction_hash_id } = this.props.message;
    if (ChatHelper.isRequestRefundMessage(this.props.message)) {
      return (
        <div className="chat-msg-user chat-msg-user--sp">
          <ChatsTime date={created_at} isMyMessage={false} />
          <figure>
            <img alt={name} src={getAvatarUrl(avatar_url)} />
          </figure>
          <p>
            <strong>{name}</strong> prosi o zwrot{' '}
            <strong>{displayNiceAmount(transaction_amount)} zł</strong>
            <a
              onClick={(e) => this.handleClickMakeRefund(e, transaction_hash_id)}
              className="btn"
            >
              Wykonaj
            </a>
          </p>
        </div>
      );
    } else if (ChatHelper.isDoneRefundMessage(this.props.message)) {
      return (
        <div className="chat-msg-user chat-msg-user--sp">
          <ChatsTime date={created_at} isMyMessage={false} />
          <figure>
            <img alt={name} src={getAvatarUrl(avatar_url)} />
          </figure>
          <p>Wykonałeś zwrot <strong>{displayNiceAmount(transaction_amount)} zł</strong></p>
        </div>
      );
    }
  }

  renderClientMessage = () => {
    var { transaction_amount, avatar_url, name, created_at } = this.props.message;
    if (ChatHelper.isRequestRefundMessage(this.props.message)) {
      return (
        <div className="chat-msg-user chat-msg-user--sp">
          <ChatsTime date={created_at} isMyMessage={false} />
          <figure>
            <img alt={name} src={getAvatarUrl(avatar_url)} />
          </figure>
          <p>Poprosiłeś o zwrot <strong>{displayNiceAmount(transaction_amount)} zł</strong></p>
        </div>
      );
    } else if (ChatHelper.isDoneRefundMessage(this.props.message)) {
      return (
        <div className="chat-msg-user chat-msg-user--sp">
          <ChatsTime date={created_at} isMyMessage={false} />
          <figure>
            <img alt={name} src={getAvatarUrl(avatar_url)} />
          </figure>
          <p><strong>{name}</strong> wykonał zwrot <strong>{displayNiceAmount(transaction_amount)} zł</strong></p>
        </div>
      );
    }
  }

  render() {
    var { isMyMessage, isServiceProvider } = this.props;
    return (
      <React.Fragment>
        <ChatsModal ref={this.refundModal} title="Zwrot">
          <RefundModalContent
            transactionHashId={this.state.transactionHashId}
            isServiceProvider={this.props.isServiceProvider}
            chatHash={this.props.chatHash}
            conversationId={this.props.conversationId}
          />
        </ChatsModal>
        { isServiceProvider && (
          this.renderServiceProviderMessage()
        )}
        { !isServiceProvider && (
          this.renderClientMessage()
        )}
      </React.Fragment>
    );
  }
}

ChatRefundMessage.propTypes = {
  message: PropTypes.object
};

export default ChatRefundMessage;
