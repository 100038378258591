import React from "react"
import axios from 'axios';
import { ChatHelper } from '../../helpers/chat_helper';
import { displayNiceAmount } from '../../helpers/common_helper';
import PaymentComponent from './PaymentComponent';

const VARIANT_PAYMENT = 'payment';
const VARIANT_CONFIRM = 'confirm';
const VARIANT_COMPLETE = 'complete';

class RefundModalMakePayment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      error: null,
      transaction: null,
      sectionVariant: null,
      paymentClientSecret: null,
      cards: [],
      defaultPaymentMethodId: null,
      // error: null,
      // loading: true,
      toPayAmount: null,
      stripePublicKey: null,
      customerName: null,
      refundWay: '',
      paymentTransaction: null
    };
  }

  componentDidMount() {
    this.checkBalance();
  }

  checkBalance = () => {
    var _this = this;
    var params = { transaction_hash_id: this.props.transactionHashId };
    this.setState({ loading: true, error: null }, () => {
      axios.post(`/dashboard/chats/check_balance`, params, {
        timeout: 3000,
        headers: {
          'Content-Type': 'application/json',
          'accept': 'application/json',
        }
      })
        .then(function (response) {
          console.log("done", response)
          if (response.data.msg == 'no_funds') {
            // no funds - show Stripe form to charge
            _this.setState({
              sectionVariant: VARIANT_PAYMENT,
              loading: false,
              transaction: response.data.transaction,
              paymentClientSecret: response.data.intent_client_secret,
              cards: response.data.cards,
              defaultPaymentMethodId: response.data.default_payment_method_id,
              stripePublicKey: response.data.stripe_public_key,
              customerName: response.data.customer_name,
              refundWay: response.data.refund_way,
              toPayAmount: response.data.to_pay_amount,
              paymentTransaction: response.data.payment_transaction
            }, () => {
              _this.initStripe();
            });
          } else {
            _this.setState({
              loading: false,
              sectionVariant: VARIANT_CONFIRM,
              transaction: response.data.transaction,
              refundWay: response.data.refund_way
            });
          }
        })
        .catch(function (error) {
          console.log("fail", error)
          var msg = ChatHelper.getErrorMessageFromResponse(error);
          _this.setState({ error: msg, loading: false })
        });
    });
  }

  initStripe = () => {
    var stripeObject = new StripePayment();
    stripeObject.init({
      stripeClientSecret: this.state.paymentClientSecret,
      stripePublicKey: this.state.stripePublicKey,
      customerName: this.state.customerName,
    });
    stripeObject.setupCallback(this.callbackSendAfterPaid);
    stripeObject.call('#surcharge-form');
  }

  callbackSendAfterPaid = (formData) => {
    var _this = this;
    this.setState({ error: null, loading: true }, () => {
      var { paymentTransaction, transaction } = this.state;
      // var params = {
      //   'order[payment_transaction_hash_id]': paymentTransaction.hash_id,
      //   'order[transaction_hash_id]': transaction.hash_id,
      //   ...formParams
      // };

      formData.append('order[payment_transaction_hash_id]', paymentTransaction.hash_id)
      formData.append('order[transaction_hash_id]', transaction.hash_id)
      console.log("formData", formData)

      axios.post(`/dashboard/chats/save_payment_and_refund`, formData, {
        timeout: 3000,
        headers: {
          'Content-Type': 'application/json',
          'accept': 'application/json',
        }
      })
        .then(function (response) {
          console.log("done", response)
          _this.setState({ loading: false }, () => {
            _this.props.closeModal();
          });
        })
        .catch(function (error) {
          console.log("fail", error)
          var msg = ChatHelper.getErrorMessageFromResponse(error);
          _this.setState({ error: msg, loading: false });
        });
    });
  }

  handleClickConfirmRefund = (e) => {
    e.preventDefault();
    var _this = this;
    var params = { transaction_hash_id: this.state.transaction.hash_id };
    this.setState({ loading: true, error: null }, () => {
      axios.post(`/dashboard/chats/create_refund`, params, {
        timeout: 6000,
        headers: {
          'Content-Type': 'application/json',
          'accept': 'application/json',
        }
      })
        .then(function (response) {
          console.log("done", response)
          _this.setState({
            loading: false,
            sectionVariant: VARIANT_COMPLETE
          }, () => {
            // _this.initStripe();
          });
        })
        .catch(function (error) {
          console.log("fail", error)
          var msg = ChatHelper.getErrorMessageFromResponse(error);
          _this.setState({ error: msg, loading: false })
        });
    });
  }

  render() {
    var { transaction } = this.state;
    // var buttonText = "";
    // const { isServiceProvider } = this.props;
    // if (isServiceProvider) {
    //   buttonText = "Zwróć pieniądze";
    // } else {
    //   buttonText = "Poproś o zwrot";
    // }
    return (
      <React.Fragment>
        { this.state.error && (
          <p className="modal-error">{this.state.error}</p>
        )}
        {this.state.loading
          ?
          <p>Ładowanie...</p>
          :
          (
            <React.Fragment>
              {this.state.sectionVariant == VARIANT_PAYMENT && (
                <div>
                  <p>Zwróć środki ze swojego konta.</p>
                  <p>Niestety Twoje saldo jest niewystarczające, aby pokryć zwrot.</p>
                  <p>Wpłać brakującą kwotę: {displayNiceAmount(this.state.toPayAmount)} PLN.</p>
                  <PaymentComponent
                    defaultPaymentMethodId={this.state.defaultPaymentMethodId}
                    cards={this.state.cards}
                  />
                </div>
              )}

              {this.state.sectionVariant == VARIANT_CONFIRM && (
                <React.Fragment>
                  {this.state.refundWay == 'cancel' && (
                    <p>Anuluj płatność. Kwota nie została jeszcze pobrana</p>
                  )}
                  {this.state.refundWay == 'refund' && (
                    <p>Zwróć środki ze swojego konta Hoptu</p>
                  )}
                    <p>
                      <strong>
                        {transaction.nice_desc}
                      </strong>
                    </p>
                  <input
                    type="submit"
                    className="btn btn--expanded"
                    onClick={this.handleClickConfirmRefund}
                    value="Potwierdź zwrot"
                  />
                </React.Fragment>
              )}

              {this.state.sectionVariant == VARIANT_COMPLETE && (
                <p>Zwrot został wykonany</p>
              )}
            </React.Fragment>
          )
        }
      </React.Fragment>
    );
  }
}

export default RefundModalMakePayment;
