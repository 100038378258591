import React from "react"
import PropTypes from "prop-types"
import classNames from 'classnames';
import { getAvatarUrl } from '../helpers/common_helper';
import ChatsTime from './chats/ChatsTime';
import { displayNiceAmount } from '../helpers/common_helper';
import { ChatHelper } from '../helpers/chat_helper';
import ChatsModal from './chats/ChatsModal';
import MakeSurchargeModalContent from './chats/MakeSurchargeModalContent';

class ChatSurchargeMessage extends React.Component {

  constructor(props) {
    super(props);
    this.makeSurchargeModalRef = React.createRef();
  }

  handlePayButton = (e) => {
    e.preventDefault();
    this.makeSurchargeModalRef.current.open();
  }

  // only owner (SP) can send request for money
  renderMyMessage = () => {
    var { avatar_url, name, created_at, transaction_amount } = this.props.message;
    return (
      <div className="chat-msg-user chat-msg-user--sp">
        <ChatsTime date={created_at} isMyMessage={false} />
        <figure>
          <img alt={name} src={getAvatarUrl(avatar_url)} />
        </figure>
        <p>Poprosiłeś o dopłatę <strong>{displayNiceAmount(transaction_amount)} zł</strong></p>
      </div>
    );
  }

  renderInterlocutorMessage = () => {
    var { name, avatar_url, created_at, transaction_amount, transaction_completed } = this.props.message;
    var css = classNames({
      'chat-msg-user': true,
      'chat-msg-user--button': true
    });
    var canRenderModal = ChatHelper.isSurchargeMessage(this.props.message) && !transaction_completed;
    return (
      <React.Fragment>
        {canRenderModal && (
          <ChatsModal ref={this.makeSurchargeModalRef} title="Dokonaj płatności">
            <MakeSurchargeModalContent
              chatHash={this.props.chatHash}
              conversationId={this.props.conversation}
              amount={transaction_amount}
              message={this.props.message}
            />
          </ChatsModal>
        )}
        <div className={css}>
          <ChatsTime date={created_at} isMyMessage={false} />
          <figure>
            <img alt={name} src={getAvatarUrl(avatar_url)} />
          </figure>
          <p>
            <strong>{name}</strong> wysłał prośbę o dopłatę do{' '}
          usługi: <strong>{displayNiceAmount(transaction_amount)} zł</strong>
            {transaction_completed
              ?
              <span>Opłacone</span>
              :
              <a onClick={this.handlePayButton.bind(this)} className="btn">Zapłać</a>
            }
          </p>
        </div>
      </React.Fragment>
    );
  }

  render() {
    var { isMyMessage } = this.props;
    return (
      <React.Fragment>
        { isMyMessage && (
          this.renderMyMessage()
        )}
        { !isMyMessage && (
          this.renderInterlocutorMessage()
        )}
      </React.Fragment>
    );
  }
}

ChatSurchargeMessage.propTypes = {
  message: PropTypes.object
};

export default ChatSurchargeMessage;
