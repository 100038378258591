import React from "react"
import classNames from 'classnames';

class ChatsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { show: false };
  }

  open = () => {
    this.setState({ show: true });
  }

  close = () => {
    this.setState({ show: false });
  }

  render() {
    if (this.state.show == false) {
      return null;
    }

    var modalClass = classNames({
      'md-modal': true,
      'md-effect-1': true,
      'md-show': this.state.show
    });
    return (
      <div>
        <div className={modalClass}>
          <div className="md-content">
          <h2>{this.props.title}</h2>
            {React.cloneElement(this.props.children, { show: this.state.show, closeModal: this.close })}
            <button className="md-close" onClick={this.close}>
              <span className="visuallyhidden">Zamknij</span>
            </button>
          </div>
        </div>
        <div className="md-overlay" onClick={this.close}></div>
      </div>
    );
  }
}

export default ChatsModal;
