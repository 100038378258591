import React from "react"

class ChatAnswers extends React.Component {

  displayAnswers = () => {
    var { answers } = this.props;
    var components = []
    for (var i = 0; i < answers.length; i++) {
      var answer = answers[i];
      components.push(
        <div key={i} className="chat-question">
          <p className="chat-question__title">{answer.question_content}</p>
          <p><em>{answer.reply}</em></p>
        </div>
      );
    }
    return components;
  }

  render() {
    return this.displayAnswers();
  }
}

export default ChatAnswers
