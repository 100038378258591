import React from "react"
import axios from 'axios';
import { ChatHelper } from '../../helpers/chat_helper';
import PaymentComponent from './PaymentComponent';

class MakeSurchargeModalContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentClientSecret: null,
      cards: [],
      defaultPaymentMethodId: null,
      error: null,
      loading: true,
      stripePublicKey: null,
      customerName: null
    };
  }

  componentDidMount() {
    if (this.props.show) {
      this.fetchPaymentsData();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.show == false && this.props.show == true) {
      this.fetchPaymentsData();
    }
  }

  fetchPaymentsData = () => {
    var _this = this;
    var params = { message_hash_id: this.props.message.hash_id };
    this.setState({ loading: true, error: null }, () => {
      axios.post(`/dashboard/chats/surcharge_data`, params, {
        timeout: 5500,
        headers: {
          'Content-Type': 'application/json',
          'accept': 'application/json',
        }
      })
        .then(function (response) {
          console.log("done", response)
          _this.setState({
            loading: false,
            paymentClientSecret: response.data.intent_client_secret,
            cards: response.data.cards,
            defaultPaymentMethodId: response.data.default_payment_method_id,
            stripePublicKey: response.data.stripe_public_key,
            customerName: response.data.customer_name
          }, () => {
            _this.initStripe();
          });
        })
        .catch(function (error) {
          console.log("fail", error)
          var msg = ChatHelper.getErrorMessageFromResponse(error);
          _this.setState({ error: msg, loading: false })
        });
    });
  }

  initStripe = () => {
    var stripeObject = new StripePayment();
    stripeObject.init({
      stripeClientSecret: this.state.paymentClientSecret,
      stripePublicKey: this.state.stripePublicKey,
      customerName: this.state.customerName,
    });
    stripeObject.setupCallback(this.callbackSendAfterPaid);
    stripeObject.call('#surcharge-form');
  }

  callbackSendAfterPaid = (formData) => {
    var _this = this;
    this.setState({ error: null, loading: true }, () => {
      var params = formData;
      axios.post(`/dashboard/chats/save_surcharge`, params, {
        timeout: 3500,
        headers: {
          'Content-Type': 'application/json',
          'accept': 'application/json',
        }
      })
        .then(function (response) {
          console.log("done", response)
          _this.setState({ loading: false }, () => {
            _this.props.closeModal();
          });
        })
        .catch(function (error) {
          console.log("fail", error)
          var msg = ChatHelper.getErrorMessageFromResponse(error);
          _this.setState({ error: msg, loading: false });
        });
    });
  }

  renderContent = () => {
    if (this.state.loading) {
      return (
        <p style={{ padding: '75px 25px'}}><i className="ic ic--loader"></i></p>
      );
    } else if (this.state.error == null) {
      return (
        <PaymentComponent
          defaultPaymentMethodId={this.state.defaultPaymentMethodId}
          cards={this.state.cards}
        />
      );
    }
  }

  render() {
    return (
      <div className="md-content__form">
        { this.state.error && (
          <div>
            <p style={{ color: 'red' }}>{this.state.error}</p>
          </div>
        )}
        {this.renderContent()}
      </div>
    );
  }
}

export default MakeSurchargeModalContent;
