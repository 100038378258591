import React from "react"
import axios from 'axios';
import { ChatHelper } from '../../helpers/chat_helper';
import RefundModalChoosePayments from './RefundModalChoosePayments';
import RefundModalMakePayment from './RefundModalMakePayment';

class RefundModalContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      transactionHashId: null
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.transactionHashId) {
      return {
        step: 2,
        transactionHashId: props.transactionHashId
      }
    } else {
      return null;
    }
  }

  // componentDidMount() {
  //   if (this.props.show && this.canFetchRefunds()) {
  //     this.fetchRefundsData();
  //   }
  // }

  // componentDidUpdate(prevProps) {
  //   if (prevProps.show == false && this.props.show == true && this.canFetchRefunds()) {
  //     this.fetchRefundsData();
  //   }
  // }

  // showPaymentSection = () => {
  //   return this.props.transactionHashId != null;
  // }

  // canFetchRefunds = () => {
  //   return !this.showPaymentSection();
  // }

  requestForRefund = (transactionHashId) => {
    var _this = this;
    var params = {
      conversation_id: this.props.conversationId,
      refund: true,
      chat_hash: this.props.chatHash,
      transaction_hash_id: transactionHashId,
    };
    this.setState({ loading: true, error: null }, () => {
      axios.post(`/message/create`, params, {
        timeout: 3000,
        headers: {
          'Content-Type': 'application/json',
          'accept': 'application/json',
        }
      })
        .then(function (response) {
          console.log("done", response)
          _this.setState({
            loading: false
          }, () => {
            _this.props.closeModal();
          });
        })
        .catch(function (error) {
          console.log("fail", error)
          var msg = ChatHelper.getErrorMessageFromResponse(error);
          _this.setState({ error: msg, loading: false })
        });
    });
  }

  // fetchRefundsData = () => {
  //   var _this = this;
  //   var params = { conversation_id: this.props.conversationId };
  //   this.setState({ loading: true, error: null }, () => {
  //     axios.post(`/dashboard/chats/fetch_possible_refunds`, params, {
  //       timeout: 1500,
  //       headers: {
  //         'Content-Type': 'application/json',
  //         'accept': 'application/json',
  //       }
  //     })
  //       .then(function (response) {
  //         console.log("done", response)
  //         _this.setState({
  //           loading: false,
  //           transactions: response.data
  //         }, () => {
  //           // _this.initStripe();
  //         });
  //       })
  //       .catch(function (error) {
  //         console.log("fail", error)
  //         var msg = ChatHelper.getErrorMessageFromResponse(error);
  //         _this.setState({ error: msg, loading: false })
  //       });
  //   });
  // }

  // handleInputChange = (e) => {
  //   this.setState({ value: e.target.value, error: null });
  // }

  handleClickChooseRefund = (hashId) => {
    if (this.props.isServiceProvider) {
      // this.requestForRefund(hashId);
      this.setState({ step: 2, transactionHashId: hashId });

    } else {
      this.requestForRefund(hashId);
    }
  }

  render() {
    return (
      <React.Fragment>
        {
          this.state.step == 1
            ?
            <RefundModalChoosePayments
              handleClickChooseRefund={this.handleClickChooseRefund}
              {...this.props}
            />
            :
            <RefundModalMakePayment
              transactionHashId={this.state.transactionHashId}
              {...this.props}
            />
        }
      </React.Fragment>
    );
  }
}

export default RefundModalContent;
