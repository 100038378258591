import React from "react"
import PropTypes from "prop-types"
import classNames from 'classnames';
import moment from 'moment';
import ChatsModal from './chats/ChatsModal';
import SurchargeModalContent from './chats/SurchargeModalContent';
import RefundModalContent from './chats/RefundModalContent';
import { connect } from 'react-redux';
import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/moment';
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core";
import deepOrange from "@material-ui/core/colors/deepOrange";

const defaultMaterialTheme = createMuiTheme({
  palette: {
    primary: deepOrange,
  },
});

class ChatTyping extends React.Component {
  constructor(props) {
    super(props)
    this.surchargeModalRef = React.createRef();
    this.refundModalRef = React.createRef();
    this.state = {
      message: '',
      timeMeeting: moment().add(1, 'hours'),
      errorTimeMeeting: null
    }
  }

  updateMessage = (event) => {
    this.setState({ message: event.target.value });
  }

  commonSendMessage = () => {
    var message = this.state.message.trim();
    if (message == '') {
      return;
    }
    this.props.sendMessage(message);
    this.setState({ message: '' })
    this.props.scrollToBottomActiveChat();
  }

  commonSendTime = () => {
    var timeMeeting = this.state.timeMeeting;
    if (!timeMeeting) {
      return;
    }
    if (this.state.errorTimeMeeting) {
      return;
    }
    this.props.sendMessage(timeMeeting, true);
    this.setState({ timeMeeting: null })
    this.props.scrollToBottomActiveChat();
  }

  handlePressKeyDown = (event) => {
    if (event.key == 'Enter') {
      this.commonSendMessage();
    }
  }

  sendMessage = (event) => {
    if (this.state.message != '') {
      this.commonSendMessage();
    }
  }

  sendTimeMeeting = (event) => {
    if (this.state.timeMeeting) {
      this.commonSendTime();
    }
  }

  handleSurchargeClick = (e) => {
    e.preventDefault();
    this.surchargeModalRef.current.open();
  }

  handleRefundClick = (e) => {
    e.preventDefault();
    this.refundModalRef.current.open();
  }

  outsideClickListener = e => {
    var paymentMenu = document.getElementById("payment-menu");
    var paymentMenuLabel = document.getElementById("payment-menu-label");

    if (!paymentMenu.contains(e.target)) {
      paymentMenu.style.display = "none";
      paymentMenuLabel.classList.remove("active");
      this.removeClickListener();
    }
  }

  removeClickListener = () => {
    document.removeEventListener('click', this.outsideClickListener);
  }

  showPaymentMenu = e => {
    var paymentMenu = document.getElementById("payment-menu");

    if (paymentMenu.style.display !== "block") {
      paymentMenu.style.display = "block"
      e.target.classList.add("active");
      document.addEventListener("click", this.outsideClickListener);
    }
  }

  getCurrentConversation = () => {
    var { conversations, activeChatIndex } = this.props;
    if (activeChatIndex != null) {
      return conversations[activeChatIndex];
    } else {
      return null;
    }
  }

  canShowTime = () => {
    var conversation = this.getCurrentConversation();
    if (conversation) {
      return conversation.accepted_time == null && this.props.isServiceProvider;
    } else {
      return false;
    }
  }

  getServiceName = () => {
    var conversation = this.getCurrentConversation();
    if (conversation) {
      return conversation.service_name
    } else {
      return '';
    }
  }

  canShowSurcharge = () => {
    return this.props.isServiceProvider;
  }

  changeMeetingTime = (value) => {
    var now = moment();
    if (!value.isAfter(now)) {
      this.setState({ timeMeeting: value, errorTimeMeeting: 'Niepoprawna godzina spotkania' });
    } else {
      this.setState({ timeMeeting: value, errorTimeMeeting: null });
    }
  }

  render() {
    var showTime = this.canShowTime();
    var showSurcharge = this.canShowSurcharge();
    var css = classNames({
      'chat-typing': true,
      'chat-typing--time': showTime
    });

    return (
      <div className={css}>
        {showTime && (
          <div className="chat-typing__time-wrapper">
            <div className="chat-typing__time">
              <label htmlFor="suggest-time"><i className="ic ic--clock"></i> Zaproponuj godzinę</label>
              <div>
                <ThemeProvider theme={defaultMaterialTheme}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <TimePicker
                      className="hoptu-time-picker"
                      cancelLabel="Anuluj"
                      todayLabel="Dzisiaj"
                      autoOk
                      emptyLabel='-wybierz-'
                      label={null}
                      value={this.state.timeMeeting}
                      onChange={this.changeMeetingTime}
                      ampm={false}
                    />
                  </MuiPickersUtilsProvider>
                </ThemeProvider>
                {this.state.errorTimeMeeting && (
                  <p style={{ fontSize: '12px', color: 'red' }}>
                    {this.state.errorTimeMeeting}
                  </p>
                )}
              </div>
              <div>
                <button className="btn btn--send" onClick={this.sendTimeMeeting}><i className="ic ic--send" aria-hidden="true"></i></button>
              </div>
            </div>
          </div>
        )}
        <div className="chat-typing__message-wrapper">
          <div className="chat-typing__message">
            <div className="chat-typing__message-input" id="payment-menu-wrapper">
              <ul id="payment-menu">
                {showSurcharge && (
                  <li><a href="#" onClick={this.handleSurchargeClick}>Dopłata</a></li>
                )}
                <li><a href="#" onClick={this.handleRefundClick}>Zwrot</a></li>
              </ul>

              <label id="payment-menu-label" onClick={this.showPaymentMenu}></label>

              <label htmlFor="fileupload" className="input-file">
                <input
                  id="fileupload"
                  type="file"
                  name="files[]"
                  data-url={`/message/create-attachment/${this.props.chatHash}`}
                  multiple />
              </label>
              <div className="input-text">
                <input
                  id="chat-send-message"
                  onKeyDown={this.handlePressKeyDown}
                  onChange={this.updateMessage}
                  value={this.state.message}
                  type="text"
                  placeholder="Wpisz wiadomość"
                />
              </div>
            </div>
            <div className="chat-typing__message-button">
              <button className="btn btn--send" onClick={this.sendMessage}>
                <i className="ic ic--send" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </div>

        <ChatsModal ref={this.surchargeModalRef} title="Dopłata do usługi">
          <SurchargeModalContent
            chatHash={this.props.chatHash}
            conversationId={this.props.conversationId}
            serviceName={this.props.serviceName}
          />
        </ChatsModal>

        <ChatsModal ref={this.refundModalRef} title="Zwrot">
          <RefundModalContent
            isServiceProvider={this.props.isServiceProvider}
            chatHash={this.props.chatHash}
            conversationId={this.props.conversationId}
          />
        </ChatsModal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    activeChatIndex: state.chat.activeChatIndex,
    chatHash: state.chat.chatHash,
    isServiceProvider: state.chat.isServiceProvider
  };
};

export default
  connect(
    mapStateToProps,
    null
  )(ChatTyping);
