import React from "react"
import axios from 'axios';
import { ChatHelper } from '../../helpers/chat_helper';

class RefundModalChoosePayments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      error: null,
      transactions: [],
    };
  }

  componentDidMount() {
    this.fetchRefundsData();
  }

  // componentDidUpdate(prevProps) {
  //   if (prevProps.show == false && this.props.show == true && this.canFetchRefunds()) {
  //     this.fetchRefundsData();
  //   }
  // }

  // showPaymentSection = () => {
  //   return this.props.transactionHashId != null;
  // }

  // canFetchRefunds = () => {
  //   return !this.showPaymentSection();
  // }

  // requestForRefund = (transactionHashId) => {
  //   var _this = this;
  //   var params = {
  //     conversation_id: this.props.conversationId,
  //     refund: true,
  //     chat_hash: this.props.chatHash,
  //     transaction_hash_id: transactionHashId,
  //   };
  //   this.setState({ loading: true, error: null }, () => {
  //     axios.post(`/message/create`, params, {
  //       timeout: 1500,
  //       headers: {
  //         'Content-Type': 'application/json',
  //         'accept': 'application/json',
  //       }
  //     })
  //       .then(function (response) {
  //         console.log("done", response)
  //         _this.setState({
  //           loading: false
  //         }, () => {
  //           _this.props.closeModal();
  //         });
  //       })
  //       .catch(function (error) {
  //         console.log("fail", error)
  //         var msg = ChatHelper.getErrorMessageFromResponse(error);
  //         _this.setState({ error: msg, loading: false })
  //       });
  //   });
  // }

  fetchRefundsData = () => {
    var _this = this;
    var params = { conversation_id: this.props.conversationId };
    this.setState({ loading: true, error: null }, () => {
      axios.post(`/dashboard/chats/fetch_possible_refunds`, params, {
        timeout: 1500,
        headers: {
          'Content-Type': 'application/json',
          'accept': 'application/json',
        }
      })
        .then(function (response) {
          console.log("done", response)
          _this.setState({
            loading: false,
            transactions: response.data
          }, () => {
            // _this.initStripe();
          });
        })
        .catch(function (error) {
          console.log("fail", error)
          var msg = ChatHelper.getErrorMessageFromResponse(error);
          _this.setState({ error: msg, loading: false })
        });
    });
  }

  // handleInputChange = (e) => {
  //   this.setState({ value: e.target.value, error: null });
  // }

  handleClickRefund = (e, hashId) => {
    e.preventDefault();
    this.props.handleClickChooseRefund(hashId);
  }

  render() {
    var buttonText = "";
    const { isServiceProvider } = this.props;
    if (isServiceProvider) {
      buttonText = "Zwróć pieniądze";
    } else {
      buttonText = "Poproś o zwrot";
    }
    return (
      <React.Fragment>
        { this.state.error && (
          <p className="modal-error">{this.state.error}</p>
        )}
        {this.state.loading
          ?
          <p>Ładowanie...</p>
          :
          (
            <ul id="payout-table">
              {this.state.transactions.map((transaction, index) => (
                <li key={index}>
                  <span className="desc">
                    {transaction.nice_desc}
                  </span>
                  <span className="time">
                    {transaction.time_of_creation}
                  </span>
                  <div className="button-wrapper">
                    <a className="btn btn-expanded" onClick={(e) => this.handleClickRefund(e, transaction.hash_id)} href="">
                      {buttonText}
                    </a>
                  </div>
                </li>
              ))}
            </ul>
          )
        }
      </React.Fragment>
    );
  }
}

export default RefundModalChoosePayments;
