import React from "react"
import classNames from 'classnames';

class NotificationMain extends React.Component {

  render() {
    return (
      <ul className="header__links">
        <li>
          <a
            className={classNames({ 'links-unread': this.props.unreadNotifications })}
            onClick={this.props.handleClickNotificationIcon}
            href=""
            title="Powiadomienia"
          >
            <i className="ic ic--bell" aria-hidden="true"></i>
          </a>
        </li>

        <li>
          <a
            className={classNames({ 'links-unread': this.props.unreadChatNotifications })}
            onClick={this.props.handleClickChatIcon}
            href=""
            title="Wiadomości"
          >
            <i className="ic ic--msg" aria-hidden="true"></i>
          </a>
        </li>

        <li>
          <a
            href={this.props.logoutPath}
            title="Wyloguj się"
            rel="nofollow"
            data-method="delete"
          >
            <i className="ic ic--sign-out" aria-hidden="true"></i>
          </a>
        </li>
      </ul>
    );
  }
}

export default NotificationMain;
